
/* =Team Member
   ========================================================================== */

    .ewf-team-member {
		margin-bottom: 30px;
		position: relative;
		overflow: hidden;
	}
	
	.ewf-team-member:after {
		visibility: hidden;
		display: block;
		height: 0;
		font-size: 0;
		content: " ";
		clear: both;		
	}
	
	.ewf-team-member__thumbnail {
		position: relative;
		overflow: hidden;
		margin-bottom: 30px;
	}
	
	.ewf-team-member__thumbnail img {
		border-radius: 15px;
	}
	
	.ewf-team-member:hover .ewf-team-member__thumbnail img {

	}
	
	.ewf-team-member__details {
		
	}
	
	.ewf-team-member__name {
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: 500;
	}
	
	.ewf-team-member__title {
		margin-bottom: 0;
		font-weight: 400;
		text-transform: uppercase;
	}
