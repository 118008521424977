
/* ==========================================================================
   =Media
   ========================================================================== */   
   
	/* Make sure embeds and iframes fit their containers. */
	embed,
	iframe,
	object,
	video {
		margin-bottom: 1.75em;
		max-width: 100%;
		vertical-align: middle;
	}
   
/* =Captions
   ========================================================================== */
   
    .wp-caption {
		margin-bottom: 30px;
		max-width: 100%;
	}

	.wp-caption img[class*="wp-image-"] {
		display: block;
		margin: 0 auto;
	}

	.wp-caption .wp-caption-text {
		padding: 15px 0;
	}

/* =Galleries
   ========================================================================== */

	.gallery { 
		margin-bottom: 30px; 	
	}

	.gallery-item {
		float: left;
		width: 100%;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;
		padding: 1.79104477%;
		text-align: center;
		vertical-align: top;
	}

	.gallery-columns-2 .gallery-item { max-width: 50%; }
	.gallery-columns-3 .gallery-item { max-width: 33.33%; }
	.gallery-columns-4 .gallery-item { max-width: 25%; }
	.gallery-columns-5 .gallery-item { max-width: 20%; }
	.gallery-columns-6 .gallery-item { max-width: 16.66%; }
	.gallery-columns-7 .gallery-item { max-width: 14.28%; }
	.gallery-columns-8 .gallery-item { max-width: 12.5%; }
	.gallery-columns-9 .gallery-item { max-width: 11.11%; }

	.gallery-icon img { margin: 0 auto; }

	.gallery-caption {
		display: block;
		padding: 15px 0;
	}

	.gallery-columns-6 .gallery-caption,
	.gallery-columns-7 .gallery-caption,
	.gallery-columns-8 .gallery-caption,
	.gallery-columns-9 .gallery-caption {
		display: none;
	} 
	