
/* ==========================================================================
   =Typography
   ========================================================================== */

	body {
		font-family: 'Lato', sans-serif;
		font-size: 18px;
		font-weight: 300;
		line-height: 30px;
		color: $color-text-general;
		background-color: #ffffff; 
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color-text-headings;
		font-family: 'Poppins', sans-serif;
	}

	h1 {
		margin-top: 3px; 
		margin-bottom: 3px; 	
		font-size: 45px;
		line-height: 54px;
		font-weight: 500;
	}

	h2 {
		margin-top: 9px; 
		margin-bottom: 9px;
		font-size: 36px;
		line-height: 42px;		
		font-weight: 500;
	}

	h3 {
		margin-top: 12px; 
		margin-bottom: 12px;
		font-size: 30px;
		line-height: 36px;		
		font-weight: 500;
	}

	h4 {
		margin-top: 14px;  
		margin-bottom: 14px; 
		font-size: 22px;
		line-height: 32px;		
		font-weight: 500;
	}

	h5 {
		margin-top: 3px; 
		margin-bottom: 3px; 
		font-size: 20px;
		line-height: 24px;		
		font-weight: 500;
	}

	h6 {
		margin-top: 6px; 
		margin-bottom: 6px;
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;	
	}
	
	h1:first-child,
	h2:first-child,
	h3:first-child,
	h4:first-child,
	h5:first-child,
	h6:first-child { margin-top: 0; }

	p { margin-bottom: 30px; }

	em { font-style: italic; }
	
	strong { font-weight: 500; }
	
	small { font-size: 90%; }
	
	big { font-size: 125%; }
	
	sub { 
		vertical-align: sub; 
		font-size: 75%; 
	}
	
	sup { 
		vertical-align: super; 
		font-size: 75%; 
	}
	
	abbr[title] {  
		border-bottom: 1px dotted $color-text-general; 
		cursor: help;
	}
	
	address { 
		display: block; 
		margin-bottom: 30px;
	}
	
	q {
		color: $color-text-headings;
		quotes: "\201C" "\201D" "\201C" "\201D";
	}
	
	q:before { content: open-quote; }
	
	q:after { content: close-quote; }
	
	blockquote {
		overflow: hidden;
		margin-bottom: 30px;
		color: $color-text-headings;
		quotes: "" "" "" "";
	}
	
	blockquote:before { content: open-quote; }
	
	blockquote:after { content: close-quote; }
	
	blockquote > *:last-child { margin-bottom: 0; }

	blockquote cite {
		display: block;
		margin-top: 15px;
		color: $color-text-general;
	}
	
	blockquote cite::before {
		content: '';
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
		width: 15px;
		border-top: 1px solid $color-text-general;
	}

	kbd,
	tt,
	var,
	samp,	
	code, 
	pre { font-family: monospace; }
	
	code {}
	
	pre { 
		display: block;
		overflow-x: auto; 
		margin-bottom: 30px;	
		white-space: pre-wrap;
	}
	
	mark,
	ins { text-decoration: none; }
	
	s,
	del { text-decoration: line-through; }
	
/* ==========================================================================
   =Forms
   ========================================================================== */

	form {}
	
	fieldset { display: block; }

	fieldset legend {}
	
	label { display: block; }
	
	/**
	 * 1. IE input fix. 
	 */
	
	input[type="text"],
	input[type="password"],
	input[type="date"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="month"],
	input[type="week"],
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="tel"],
	input[type="time"],
	input[type="url"],
	input[type="color"],
	textarea {
		display: block;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
			    box-sizing: border-box;
		width: 100%;
		height: 60px; /* 1 */	
		padding: 14px 30px;
		border: 1px solid $color-border-forms;
		border-radius: 30px;
		margin-bottom: 30px;
		background-color: $color-background-forms;
		color: $color-text-forms;
		-webkit-appearance: none;
	}

	input[type="text"]:focus,
	input[type="password"]:focus,
	input[type="date"]:focus,
	input[type="datetime"]:focus,
	input[type="datetime-local"]:focus,
	input[type="month"]:focus,
	input[type="week"]:focus,
	input[type="email"]:focus,
	input[type="number"]:focus,
	input[type="search"]:focus,
	input[type="tel"]:focus,
	input[type="time"]:focus,
	input[type="url"]:focus,
	input[type="color"]:focus,
	textarea:focus {
		border-color: $color-border-forms-focus;
		outline: none;
	}

	input[type="text"]:disabled,
	input[type="password"]:disabled,
	input[type="date"]:disabled,
	input[type="datetime"]:disabled,
	input[type="datetime-local"]:disabled,
	input[type="month"]:disabled,
	input[type="week"]:disabled,
	input[type="email"]:disabled,
	input[type="number"]:disabled,
	input[type="search"]:disabled,
	input[type="tel"]:disabled,
	input[type="time"]:disabled,
	input[type="url"]:disabled,
	input[type="color"]:disabled,
	textarea:disabled {
		cursor: not-allowed;
		background-color: $color-background-forms-disabled;
	}

	input[type="text"][disabled],
	input[type="text"][readonly],
	fieldset[disabled] input[type="text"],
	input[type="password"][disabled],
	input[type="password"][readonly],
	fieldset[disabled] input[type="password"],
	input[type="date"][disabled],
	input[type="date"][readonly],
	fieldset[disabled] input[type="date"],
	input[type="datetime"][disabled],
	input[type="datetime"][readonly],
	fieldset[disabled] input[type="datetime"],
	input[type="datetime-local"][disabled],
	input[type="datetime-local"][readonly],
	fieldset[disabled] input[type="datetime-local"],
	input[type="month"][disabled],
	input[type="month"][readonly],
	fieldset[disabled] input[type="month"],
	input[type="week"][disabled],
	input[type="week"][readonly],
	fieldset[disabled] input[type="week"],
	input[type="email"][disabled],
	input[type="email"][readonly],
	fieldset[disabled] input[type="email"],
	input[type="number"][disabled],
	input[type="number"][readonly],
	fieldset[disabled] input[type="number"],
	input[type="search"][disabled],
	input[type="search"][readonly],
	fieldset[disabled] input[type="search"],
	input[type="tel"][disabled],
	input[type="tel"][readonly],
	fieldset[disabled] input[type="tel"],
	input[type="time"][disabled],
	input[type="time"][readonly],
	fieldset[disabled] input[type="time"],
	input[type="url"][disabled],
	input[type="url"][readonly],
	fieldset[disabled] input[type="url"],
	input[type="color"][disabled],
	input[type="color"][readonly],
	fieldset[disabled] input[type="color"],
	textarea[disabled],
	textarea[readonly],
	fieldset[disabled] textarea {
		cursor: not-allowed;
		background-color: $color-background-forms-disabled;
	}

	textarea[rows] { height: auto; }

	/**
	 *  1. Disallow resize out of parent
	 */
	
	textarea { max-width: 100%; /* 1 */ }
	
	/**
	 * 1. IE input fix.
	 */
	
	select {
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
			    box-sizing: border-box;
		width: 100%;
		height: 60px; /* 1 */
		padding: 14px 30px;
		border: 1px solid $color-border-forms;
		border-radius: 30px;
		margin-bottom: 30px;
		background-color: $color-background-forms;
		color: $color-text-forms;
		-webkit-appearance: none;
	}

	select[multiple] { 
		height: auto;
		padding: 15px 15px;
		line-height: 30px;	
	}
	
	select:disabled { cursor: not-allowed; }
	
	select:focus { border-color: $color-border-forms-focus; }

	input[type="range"] { width: 50%; }
	
	/**
	 * 1. Firefox fix for size. or else it overflows on 320px
	 */
	
	input[type="file"]{
		font-size: 15px; /* 1 */
		height: auto;
		width: 100%;
		margin-bottom: 30px;
	}
	
	input[type="checkbox"],
	input[type="radio"] {
		margin-bottom: 0;
	}

	input[type="checkbox"] + label,
	input[type="radio"] + label {
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 15px;
		vertical-align: baseline;
	}

	input[type="reset"],
	input[type="submit"],
	input[type="button"]{
		position: relative;
		-webkit-background-clip: padding-box;
		   -moz-background-clip: padding-box;
				background-clip: padding-box;
		display: inline-block;
		padding: 8px 30px;		
		border: 2px solid $color-border-forms-button;
		border-radius: 25px;
		margin-bottom: 30px;
		background-color: $color-background-forms-button;
		color: $color-text-forms-button;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 400;
		cursor: pointer;
		-webkit-appearance: none;
	} 
	
	input[type="reset"]:hover,
	input[type="submit"]:hover,
	input[type="button"]:hover,
	input[type="reset"]:focus,
	input[type="submit"]:focus,
	input[type="button"]:focus {
		border: 2px solid $color-border-forms-button-hover;
		background-color: $color-background-forms-button-hover;
		color: $color-text-forms-button-hover;
	}
	
	input[type="reset"]:active,
	input[type="submit"]:active,
	input[type="button"]:active {
		border-color: $color-border-forms-button-active;
		background-color: $color-background-forms-button-active;
		color: $color-text-forms-button-active;
	}		

	/**
 	 * Placeholder text color -- selectors need to be separate to work.
 	 */
	 
	::-webkit-input-placeholder { color: $color-text-forms-placeholders; }

	:-moz-placeholder { color: $color-text-forms-placeholders; }

	/**
 	 * 1. Since FF19 lowers the opacity of the placeholder by default
 	 */
	 
	::-moz-placeholder {
		color: $color-text-forms-placeholders;
		opacity: 1; /* 1 */
	}

	:-ms-input-placeholder { color: $color-text-forms-placeholders; }

	/**
 	 * Form validation error
 	 */
	 
	.validation-error { 
		padding-left: 30px;
	}
	
/* ==========================================================================
   =Formatting
   ========================================================================== */

	hr {
		height: 1px;
		border: 0;
		margin: 30px 0;
		background-color: $color-border-general;
	}

/* ==========================================================================
   =Lists
   ========================================================================== */
   
	ul, 
	ol { 
		margin-bottom: 30px;
		list-style-position: inside; 
	}
	 
	li > ul, 
	li > ol { 
		margin-bottom: 0; 
		margin-left: 30px;
	}
	
	li {}
	
	ul { list-style-type: disc; }
	
	ul.square,
	ul.square ul { list-style-type: square; }		
	
	ol { list-style-type: decimal; }
	
	dl {}

	dt { font-weight: bold; }

	dd { margin-bottom: 30px; } 

/* ==========================================================================
   =Images
   ========================================================================== */
	
	img { 
		max-width: 100%;
		height: auto;
		border: none; 
		vertical-align: middle;
	}
	
/* ==========================================================================
   =Tables
   ========================================================================== */

	table,
	th,
	td { border: 1px solid $color-border-general; }

	/**
	 * 1. Prevents HTML tables from becoming too wide
	 */

	table {
		width: 100%;
		border-width: 1px 0 0 1px;
		border-collapse: collapse;
		border-spacing: 0;
		margin-bottom: 30px;
		table-layout: fixed; 	/* 1 */
	}

	caption,
	th,
	td {
		font-weight: normal;
		text-align: left;
	}

	caption { 
		margin-bottom: 30px;
	}
	
	th {
		border-width: 0 1px 1px 0;
		font-weight: 400;
	}

	td { border-width: 0 1px 1px 0; }

	th, 
	td { 
		padding: 15px; 
	}
	 
	td { 
		font-weight: 300;
	}

/* ==========================================================================
   =Links
   ========================================================================== */

	a {
		color: $color-text-link;
		text-decoration: none;
		outline: 0;
		transition: color 0.15s;
	}

	a:focus { color: $color-text-link-hover; }

	a:hover { color: $color-text-link-hover; }
	
	a:active { color: $color-text-link-active; }
