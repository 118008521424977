
/* ==========================================================================
   =Custom search form 
   ========================================================================== */
	
	#custom-search {
		position: relative;
		display: inline-block;
	}
	
	#custom-search-button { 
		display: inline-block;
		color: $color-text-custom-search;
	}
	
	#custom-search-button i {
		font-size: 22px;
	}
	
	/**
 	 * 1. z-index is 1030 because the menu has a z-index of 1025 
 	 */
	 
	#custom-search-form {
		position: absolute;
		z-index: 1030; 	/* 1 */
		top: 40px;
		right: -40px;
		display: none;
	}

	#custom-search-form:before {
		position: absolute; 
		z-index: 1; 
		top: -10px; 
		right: 40px; 
		width: 0; 
		height: 0;
		border-right: 7px solid transparent; 
		border-bottom: 10px solid $color-border-general; 
		border-left: 7px solid transparent; 
		content: "";
	}
	
	#custom-search-submit { display: none; }
	
	#custom-search-form #s {
		width: 230px;
		position: relative;
		box-shadow: 0 1px 3px rgba(0,0,0,0.3);
	}
	
	#custom-search-form #s:focus { border-color: $color-border-general; }
		
		
	@media (min-width: 1200px) {
		
		#custom-search-button i {
			font-size: 26px;
			
		}
		
		#custom-search-button { 
			margin-left: 15px;
			padding-top: 5px;
		}
		
		#custom-search-form {
			top: 50px;
		}
		
		#custom-search-form #s {
			width: 460px;
		}
		
	}	