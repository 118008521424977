
/* ==========================================================================
   =Posts
   ========================================================================== */

	.post { 
		position: relative;	
		
	}
   
	.post + .post { margin-top: 30px; }

	.post-bordered {
		padding: 29px 24px;
		border: 1px solid $color-border-general;
		border-radius: 15px;
	}
	
	@media (min-width: 768px) {
		
		.post-bordered {
			padding: 44px;
		}	
		
		.post + .post { margin-top: 45px; }
	
	}	
	
/* =Sticky Post
   ========================================================================== */

	.sticky {}
   
/* =Post Thumbnail
   ========================================================================== */
   
	.post-thumbnail {
		margin-bottom: 30px;
	}
	
	.post-thumbnail img { 
		display: block; 
		border-radius: 15px;
	}
	
	@media (min-width: 768px) {
		
		.post-thumbnail:not(:last-child) {
			margin-bottom: 30px;
		}
		
	}	

/* =Post Header
   ========================================================================== */

	.post-header {}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.post-header:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
   
/* =Post Title
   ========================================================================== */
   
	.post-title {
		margin-bottom: 0;
	}
	
/* =Post Content
   ========================================================================== */

	.post-content {}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.post-content:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
	.post-content iframe { 
		max-width: 100%; 
	}

/* =Post More Link
   ========================================================================== */
	
	.more-link {}
	
	.more-link {
		position: relative;
		background-clip: padding-box;
		display: inline-block;
		padding: 8px 30px;		
		border: 2px solid $color-border-forms-button;
		border-radius: 25px;
		background-color: $color-background-forms-button;
		color: $color-text-forms-button;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		-webkit-appearance: none; 
	}
	
	.more-link:hover,
	.more-link:focus {
		border: 2px solid $color-border-forms-button-hover;
		background-color: $color-background-forms-button-hover;
		color: $color-text-forms-button-hover;
	}
	
	.more-link i {
		font-size: 13px;
	}
	
	.more-link i:last-child {
		margin-left: 15px;
	}
	
/* =Post Meta
   ========================================================================== */
   
	.post-meta {
		padding: 5px 0 20px;
		position: relative;
	}
	
	.post-meta:after {
		visibility: hidden;
		display: block;
		height: 0;
		font-size: 0;
		content: " ";
		clear: both;		
	}
	
	.posted-on {
		padding: 0 6px;
	}
	
	.posted-on:first-child { 
		padding-left: 0; 
	}
		
	.byline {
		padding: 0 6px;
	}
	
	.byline:first-child { 
		padding-left: 0; 
	}
	
	.post-author {}
	
	.edit-link {
		padding: 0 7px;
	}
	
	.edit-link:first-child { 
		padding-left: 0; 
	}
	
	.edit-link:before {
		font-family: 'themify';
		content: "\e61c";
		padding-right: 7px;
	}

	.comments-link {
		padding: 0 7px;
	}
	
	.comments-link:first-child { 
		padding-left: 0; 
	}
	
	.comments-link:before {
		font-family: 'themify';
		content: "\e643";
		padding-right: 7px;
	}
	
	.cat-links {
		padding: 0 7px;
	}
	
	.cat-links:before {
		font-family: 'themify';
		content: "\e639";
		padding-right: 7px;
	}
	
	.cat-links a {
	}
	
	.tags-links {
		margin: 30px 0;
	}
	
	.tags-links a {
		display: inline-block;
		margin: 0 5px 10px 0;
		padding: 4px 30px;
		border-radius: 25px;
		border: 1px solid $color-border-general;
		background-color: $color-background-general;
		color: $color-text-general;
		transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
	}
	
	.tags-links a:hover{
		background-color: $color-text-link-hover;
		color: #ffffff;
		border-color: $color-text-link-hover;
	}
	
/* =Post Footer
   ========================================================================== */	
	
	.post-footer {}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.post-footer:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
