
/* =Themes
   ========================================================================== */
   // Themes apply a set of color styles to an area
   // Replace previous .contrast implementation

	.ewf-theme-contrast {
		color: $color-text-theme-contast-general;
	}
	
	.ewf-theme-contrast h1,
	.ewf-theme-contrast h2,
	.ewf-theme-contrast h3,
	.ewf-theme-contrast h4,
	.ewf-theme-contrast h5,
	.ewf-theme-contrast h6,
	.ewf-theme-contrast strong {
		color: $color-text-theme-contast-headings;
	}
	
	.ewf-theme-contrast abbr[title] {  
		border-bottom-color: $color-text-theme-contast-general; 
	}
	
	.ewf-theme-contrast q ,
	.ewf-theme-contrast blockquote,
	.ewf-theme-contrast cite { color: $color-text-theme-contast-general; }
	
	.ewf-theme-contrast hr {
		background-color: $color-border-theme-contast-general;
	}
	
	.ewf-theme-contrast table,
	.ewf-theme-contrast th,
	.ewf-theme-contrast td { 
		border-color: $color-border-theme-contast-general; 
	}
	
	.ewf-theme-contrast #wp-calendar th {
		color: $color-text-theme-contast-general;
	}
	
	.ewf-theme-contrast a { color: $color-text-theme-contast-link; }
	.ewf-theme-contrast a:focus { color: $color-text-theme-contast-link-hover; }
	.ewf-theme-contrast a:hover { color: $color-text-theme-contast-link-hover; }
	.ewf-theme-contrast a:active { color: $color-text-theme-contast-link-active; }
	
	
	/* Main Slider Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-slider__pager button {
		background-color: #ffffff;
	}
	
	/* Counter Contrast Color Scheme */
   
	.ewf-theme-contrast .ewf-counter__title,
	.ewf-theme-contrast .ewf-counter__standard,
	.ewf-theme-contrast .ewf-counter__symbol,
	.ewf-theme-contrast .ewf-counter__icon i {
		color: $color-text-theme-contast-headings;
	}   
	
	/* Generic Slider Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-generic-slider__pager .slick-dots li button {
		background-color: $color-text-theme-contast-general;
	}
	
	/* Iconbox Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-iconbox__icon i {
		color: #ffffff;
	}
	
	/* Testimonial Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-testimonial-slider__pager .slick-dots li button {
		background-color: $color-text-theme-contast-general;
	}
	
	/* Latest Posts Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-post-summary,
	.ewf-theme-contrast .ewf-post-summary * {
		border-color: $color-border-theme-contast-general;
	}
	
	/* Location Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-location {
		background-color: transparent;
		border: 1px solid $color-border-theme-contast-general;
	}
	
	/* Timeline Contrast Color Scheme */
	
	.ewf-theme-contrast .ewf-timeline__slides, 
	.ewf-theme-contrast .ewf-timeline__slides li {
		border-color: $color-border-theme-contast-general;
	}
	
	/* WordPress Widgets Contrast Color Scheme */
	
	.ewf-theme-contrast .widget_archive li,
	.ewf-theme-contrast .widget_categories li,
	.ewf-theme-contrast .widget_recent_entries li,
	.ewf-theme-contrast .widget_meta li,
	.ewf-theme-contrast .widget_pages li,
	.ewf-theme-contrast .widget_recent_entries li,
	.ewf-theme-contrast .widget_recent_entries ul li:first-child,
	.ewf-theme-contrast .widget_nav_menu li,
	.ewf-theme-contrast .widget_tag_cloud a,
	.ewf-theme-contrast a.social-icon {
		border-color: $color-border-theme-contast-general;
	}
	
	.ewf-theme-contrast #wp-calendar tbody td:not(.pad) {
		border-color: $color-border-theme-contast-general;
	}
	
	.ewf-theme-contrast .widget_rss cite,
	.ewf-theme-contrast .widget_rss .rss-date {
		color: $color-text-theme-contast-headings;
	}
   
	.ewf-theme-contrast .widget_search .search-submit {
		background-color: $color-text-accent-1;
		border-color: $color-text-accent-1;
	}
	
	.ewf-theme-contrast .ewf-widget_author_box .author-bio {
		background-color: $color-text-accent-2;
		border: 1px solid $color-border-theme-contast-general;
	}