/* ==========================================================================
   =Reset and Normalize
   ========================================================================== */
    
	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
		vertical-align: baseline;
	}
		
	body { 
		line-height: 1; 
	}

	ol, 
	ul { 
		list-style: none; 
	}

	blockquote, 
	q {
		quotes: none;
	}

	blockquote:before, 
	blockquote:after,
	q:before, 
	q:after {
		content: '';
		content: none;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	/**
	 * 1. Correct the line height in all browsers.
	 * 2. Prevent adjustments of font size after orientation changes in
	 *    IE on Windows Phone and in iOS.
	 */

	html {
	  line-height: 1; /* 1 */ 
	  -ms-text-size-adjust: 100%; /* 2 */
	  -webkit-text-size-adjust: 100%; /* 2 */
	}

	/**
	 * 1. Add the correct box sizing in Firefox.
	 * 2. Show the overflow in Edge and IE.
	 */

	hr {
	  box-sizing: content-box; /* 1 */
	  height: 0; /* 1 */
	  overflow: visible; /* 2 */
	}

	/**
	 * 1. Remove the gray background on active links in IE 10.
	 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
	 */

	a {
	  background-color: transparent; /* 1 */
	  -webkit-text-decoration-skip: objects; /* 2 */
	}

	/**
	 * Hide the overflow in IE.
	 */

	svg:not(:root) {
	  overflow: hidden;
	}

	/**
	 * 1. Correct color not being inherited.
	 *    Known issue: affects color of disabled elements.
	 * 2. Correct font properties not being inherited.
	 */

	button,
	input,
	optgroup,
	select,
	textarea {
		color: inherit; /* 1 */
		font: inherit;  /* 2 */
	}

	/**
	 * Show the overflow in IE.
	 * 1. Show the overflow in Edge.
	 */

	button,
	input { /* 1 */
	  overflow: visible;
	}

	/**
	 * Remove the inheritance of text transform in Edge, Firefox, and IE.
	 * 1. Remove the inheritance of text transform in Firefox.
	 */

	button,
	select { /* 1 */
	  text-transform: none;
	}

	/**
	 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
	 *    controls in Android 4.
	 * 2. Correct the inability to style clickable types in iOS and Safari.
	 */

	button,
	html [type="button"], /* 1 */
	[type="reset"],
	[type="submit"] {
	  -webkit-appearance: button; /* 2 */
	}

	/**
	 * 1. Correct the text wrapping in Edge and IE.
	 * 2. Correct the color inheritance from `fieldset` elements in IE.
	 * 3. Remove the padding so developers are not caught out when they zero out
	 *    `fieldset` elements in all browsers.
	 */

	legend {
	  box-sizing: border-box; /* 1 */
	  color: inherit; /* 2 */
	  display: table; /* 1 */
	  max-width: 100%; /* 1 */
	  padding: 0; /* 3 */
	  white-space: normal; /* 1 */
	}

	/**
	 * Remove the default vertical scrollbar in IE.
	 */

	textarea {
	  overflow: auto;
	}

	/**
	 * 1. Add the correct box sizing in IE 10-.
	 * 2. Remove the padding in IE 10-.
	 */

	[type="checkbox"],
	[type="radio"] {
	  box-sizing: border-box; /* 1 */
	  padding: 0; /* 2 */
	}

	/**
	 * Correct the cursor style of increment and decrement buttons in Chrome.
	 */

	[type="number"]::-webkit-inner-spin-button,
	[type="number"]::-webkit-outer-spin-button {
	  height: auto;
	}

	/**
	 * 1. Correct the odd appearance in Chrome and Safari.
	 * 2. Correct the outline style in Safari.
	 */

	[type="search"] {
	  -webkit-appearance: textfield; /* 1 */
	  outline-offset: -2px; /* 2 */
	}

	/**
	 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
	 */

	[type="search"]::-webkit-search-cancel-button,
	[type="search"]::-webkit-search-decoration {
	  -webkit-appearance: none;
	}

	/**
	 * 1. Correct the inability to style clickable types in iOS and Safari.
	 * 2. Change font properties to `inherit` in Safari.
	 */

	::-webkit-file-upload-button {
	  -webkit-appearance: button; /* 1 */
	  font: inherit; /* 2 */
	}

	/*
	 * Add the correct display in all browsers.
	 */

	summary {
	  display: list-item;
	}

	/**
	 * Add the correct display in IE.
	 */

	template {
	  display: none;
	}

	/**
	 * Add the correct display in IE 10-.
	 */

	[hidden] {
	  display: none;
	}
