
/* ==========================================================================
   =WordPress Accessibility
   ========================================================================== */

	/**
 	 * Text meant only for screen readers.
	 *
	 * 1. Many screen reader and browser combinations announce broken words as they would appear visually.
 	 */

	.screen-reader-text {
		position: absolute !important;
		overflow: hidden;
		height: 1px;
		width: 1px;
		clip: rect(1px, 1px, 1px, 1px);
		word-wrap: normal !important; /* 1 */
	}

	/**
	 * 1. Above WP toolbar.
 	 */
	
	.screen-reader-text:focus {
		z-index: 100000; /* 1 */
		top: 5px;
		left: 5px;
		display: block;
		width: auto;
		height: auto;
		padding: 15px 25px 16px;
		clip: auto !important;
		font-size: 18px;
		font-weight: 300;
		line-height: normal;
		text-decoration: none;
	}
