// Applies spacing, a border, and rounded corners to areas of content.
/* =Box
   ========================================================================== */

	.ewf-box {
		border: $border-width-thin solid $color-border-general;
		padding: $spacing-medium;
	}	
	
	.ewf-box *:last-child { margin-bottom: 0; }

	.ewf-box--xx-small { padding: $spacing-xx-small; } 
	.ewf-box--x-small { padding: $spacing-x-small; } 
	.ewf-box--small { padding: $spacing-small; } 
   