	
	// Colors 
	$color-text-general: 						#2c405b !default;
	$color-text-headings: 						#2c405b !default;

	$color-text-accent-1: 						#e75748 !default;		// orange
	$color-text-accent-2: 						#2c405b !default;		// blue

	$color-border-general: 						#e9ebf3 !default;
	$color-background-general: 					#e9ebf3 !default;
	
	// Forms
	$color-text-forms: 							#2c405b !default;
	$color-text-forms-placeholders: 			#7591b7 !default;

	$color-border-forms: 						#e9ebf3 !default;
	$color-border-forms-focus: 					#e9ebf3 !default;
	$color-border-forms-invalid: 				#f1b7be !default;

	$color-background-forms: 					#e9ebf3 !default;
	$color-background-forms-disabled: 			#e9ebf3 !default;

	$color-text-forms-button: 					#ffffff !default;
	$color-text-forms-button-hover: 			#e75748 !default;
	$color-text-forms-button-active: 			#e75748 !default;
	
	$color-border-forms-button:					#e75748 !default;
	$color-border-forms-button-hover: 			#e75748 !default;
	$color-border-forms-button-active: 			#e75748 !default;
	
	$color-background-forms-button: 			#e75748 !default;
	$color-background-forms-button-hover: 		transparent !default;
	$color-background-forms-button-active: 		transparent !default;
	
	// Links
	$color-text-link: 							#2c405b !default;
	$color-text-link-hover: 					#e75748 !default;
	$color-text-link-active: 					#e75748 !default;

	// Header
	$color-background-header-top: 				#364d7c !default;
	$color-background-header: 					#ffffff !default;

	// Logo
	$color-text-logo: 							#1a171c !default;

	// Desktop and Mobile Menu
	$color-text-menu-item: 						#2c405b !default;
	$color-text-dropdown-menu-item: 			#ffffff !default;
	$color-text-menu-item-hover: 				#e75748 !default;
	$color-text-dropdown-menu-item-hover: 		#ffffff !default;
	$color-background-menu-dropdown: 			#e75748 !default;
	$color-border-dropdown-menu-item: 			rgba(255, 255, 255, 0.25) !default;
	$color-border-dropdown-menu-item-hover: 	rgba(255, 255, 255, 0.55) !default;
	$color-text-mobile-menu-trigger: 			#e75748 !default;
	
	// Custom search
	$color-text-custom-search: 					#e75748 !default;
	
	// Page Header
	$color-background-page-header: 				#f9f9fa !default;

	// Footer
	$color-background-footer: 					#e9ebf3 !default;
	$color-background-footer-bottom: 			#2c405b !default;

	// Main Slider 
	$color-background-slider-overlay: 			#2c405b !default;
	
	
	// Section
	$color-background-general-section: 			#f9f9fa !default;
	$color-background-general-section-overlay: 	#2c405b !default;
	
	
	
	
	
	// Themes - Contrast
	$color-text-theme-contast-general: 			#ffffff !default; 
	$color-text-theme-contast-headings: 		#ffffff !default;						
	
	$color-border-theme-contast-general: 		rgba(255, 255, 255, 0.25) !default;
	
	$color-text-theme-contast-link: 			#ededef !default;
	$color-text-theme-contast-link-hover: 		#ffffff !default;
	$color-text-theme-contast-link-active: 		#ffffff !default;

	

	
	// Borders
	$border-width-thin: 						1px !default;
	$border-width-medium: 						3px  !default;
	$border-width-thick: 						5px !default;
	
	// Spacing
	$spacing-xx-small: 							15px !default;
	$spacing-x-small: 							30px !default;
	$spacing-small: 							60px !default;
	$spacing-medium: 							90px !default;
	$spacing-large: 							120px !default;
	$spacing-xl-large: 							150px !default;
	$spacing-xxl-large: 						180px !default;
	
	// Spacings: Section 
	$spacing-section-small: 					60px !default;
	$spacing-section-medium: 					90px !default;
	$spacing-section-large: 					120px !default;
	
	// rounded-corners
	
	$roundness: 15px;
	