// Overflow utility classes
/* =Scrollable
   ========================================================================== */
   
	.ewf-scrollable {
		overflow: auto;
	}

	.ewf-scrollable--none {
		overflow: hidden;
	}

	.ewf-scrollable--y {
		max-height: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
	   
	.ewf-scrollable--x {
		max-width: 100%;
		overflow: hidden;
		overflow-x: auto;
	}   