// Use border utility classes to add borders to elements
/* =Borders
   ========================================================================== */

	.ewf-border--top {
		border-top: $border-width-thin solid $color-border-general;
	}

	.ewf-border--right {
		border-right: $border-width-thin solid $color-border-general;
	}

	.ewf-border--bottom {
		border-bottom: $border-width-thin solid $color-border-general;
	}

	.ewf-border--left {
		border-left: $border-width-thin solid $color-border-general;
	}
   