// Adjust whitespace with horizontal and vertical padding spacing helpers  
/* =Padding
   ========================================================================== */
   
	.ewf-padding-top--none 			{ padding-top: 0 !important; }
	.ewf-padding-right--none 		{ padding-right: 0 !important; }
	.ewf-padding-bottom--none 		{ padding-bottom: 0 !important; }
	.ewf-padding-left--none 		{ padding-left: 0 !important; }
	.ewf-padding-vertical--none 	{ padding-top: 0; padding-bottom: 0; }
	.ewf-padding-horizontal--none 	{ padding-right: 0; padding-left: 0; }
	.ewf-padding-around--none 		{ padding: 0; }

	.ewf-padding-top--xx-small 		{ padding-top: $spacing-xx-small; }
	.ewf-padding-right--xx-small 	{ padding-right: $spacing-xx-small; }
	.ewf-padding-bottom--xx-small 	{ padding-bottom: $spacing-xx-small; }
	.ewf-padding-left--xx-small 	{ padding-left: $spacing-xx-small; }
	.ewf-padding-vertical--xx-small { padding-top: $spacing-xx-small; padding-bottom: $spacing-xx-small; }
	.ewf-padding-horizontal--xx-small{ padding-right: $spacing-xx-small; padding-left: $spacing-xx-small; }
	.ewf-padding-around--xx-small 	{ padding: $spacing-xx-small; }

	.ewf-padding-top--x-small 		{ padding-top: $spacing-x-small; }
	.ewf-padding-right--x-small 	{ padding-right: $spacing-x-small; }
	.ewf-padding-bottom--x-small 	{ padding-bottom: $spacing-x-small; }
	.ewf-padding-left--x-small 		{ padding-left: $spacing-x-small; }
	.ewf-padding-vertical--x-small 	{ padding-top: $spacing-x-small; padding-bottom: $spacing-x-small; }
	.ewf-padding-horizontal--x-small{ padding-right: $spacing-x-small; padding-left: $spacing-x-small; }
	.ewf-padding-around--x-small 	{ padding: $spacing-x-small; }

	.ewf-padding-top--small 		{ padding-top: $spacing-small; }
	.ewf-padding-right--small 		{ padding-right: $spacing-small; }
	.ewf-padding-bottom--small 		{ padding-bottom: $spacing-small; }
	.ewf-padding-left--small 		{ padding-left: $spacing-small; }
	.ewf-padding-vertical--small 	{ padding-top: $spacing-small; padding-bottom: $spacing-small; }
	.ewf-padding-horizontal--small 	{ padding-right: $spacing-small;  padding-left: $spacing-small; }
	.ewf-padding-around--small 		{ padding: $spacing-small; }

	.ewf-padding-top--medium 		{ padding-top: $spacing-medium; }
	.ewf-padding-right--medium 		{ padding-right: $spacing-medium; }
	.ewf-padding-bottom--medium 	{ padding-bottom: $spacing-medium; }
	.ewf-padding-left--medium 		{ padding-left: $spacing-medium; }
	.ewf-padding-vertical--medium 	{ padding-top: $spacing-medium; padding-bottom: $spacing-medium; }
	.ewf-padding-horizontal--medium { padding-right: $spacing-medium;  padding-left: $spacing-medium; }
	.ewf-padding-around--medium 	{ padding: $spacing-medium; }

	.ewf-padding-top--large 		{ padding-top: $spacing-large; }
	.ewf-padding-right--large 		{ padding-right: $spacing-large; }
	.ewf-padding-bottom--large 		{ padding-bottom: $spacing-large; }
	.ewf-padding-left--large 		{ padding-left: $spacing-large; }
	.ewf-padding-vertical--large 	{ padding-top: $spacing-large;  padding-bottom: $spacing-large; }
	.ewf-padding-horizontal--large 	{ padding-right: $spacing-large;  padding-left: $spacing-large; }
	.ewf-padding-around--large 		{ padding: $spacing-large; }

	.ewf-padding-top--x-large 		{ padding-top: $spacing-xl-large; }
	.ewf-padding-right--x-large 	{ padding-right: $spacing-xl-large; }
	.ewf-padding-bottom--x-large 	{ padding-bottom: $spacing-xl-large; }
	.ewf-padding-left--x-large 		{ padding-left: $spacing-xl-large; }
	.ewf-padding-vertical--x-large 	{ padding-top: $spacing-xl-large; padding-bottom: $spacing-xl-large; }
	.ewf-padding-horizontal--x-large { padding-right: $spacing-xl-large; padding-left: $spacing-xl-large; }
	.ewf-padding-around--x-large 	{ padding: $spacing-xl-large; }

	.ewf-padding-top--xx-large 		{ padding-top: $spacing-xxl-large; }
	.ewf-padding-right--xx-large 	{ padding-right: $spacing-xxl-large; }
	.ewf-padding-bottom--xx-large 	{ padding-bottom: $spacing-xxl-large; }
	.ewf-padding-left--xx-large 	{ padding-left: $spacing-xxl-large; }
	.ewf-padding-vertical--xx-large { padding-top: $spacing-xxl-large; padding-bottom: $spacing-xxl-large; }
	.ewf-padding-horizontal--xx-large{ padding-right: $spacing-xxl-large; padding-left: $spacing-xxl-large; }
	.ewf-padding-around--xx-large 	{ padding: $spacing-xxl-large; }
   