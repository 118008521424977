
/* ==========================================================================
   =Posts Navigation(s)
   ========================================================================== */
   
/* =Post Pagination
   ========================================================================== */

	.pagination {
		margin: 60px 0 60px;
	}
	
	@media (min-width: 768px) {
		.pagination {
			margin: 60px 0 0;
		}
	}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.pagination:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
	.pagination a {
		
	}	
	
	.pagination .nav-links {
		text-align: center;
	}
	
	.pagination .page-numbers {
		display: inline-block;
		margin-right: 30px;
		text-align: center;
	}
	
	.pagination .page-numbers:last-child {
		margin-right: 0;
	}	
	
	.pagination .page-numbers.current {
		min-width: 30px;
		min-height: 30px;
		padding: 5px 30px;
		border-radius: 25px;
		background-color: $color-text-accent-2;
		color: #ffffff;
	}

	@media (max-width: 767px) {
		
		.pagination .page-numbers {
			margin-right: 15px;
		}
	
		.pagination .page-numbers {
			display: none;
		}
		
		.pagination .page-numbers.prev,
		.pagination .page-numbers.next,
		.pagination .page-numbers.current {
			display: inline-block;
		}
		
		.page-numbers.prev .screen-reader-text,
		.page-numbers.next .screen-reader-text {
			clip: auto;
			height: auto;
			overflow: auto;
			position: relative !important;
			width: auto;
		}
		
	}
   
/* =Page Links
   ========================================================================== */	

	.page-links {
		margin: 30px 0;
	}
   
	.page-links .page-number {
		padding: 5px;
	}
   
	.page-links {
		color: $color-text-headings;
	}
   
/* =Post Navigation
   ========================================================================== */	

	.post-navigation {
		margin: 30px 0;
	}
   
	/*
	 * 1. Clearfix hack 
	 */
	 
	.post-navigation:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
   
	@media (min-width: 768px) {
		
		.post-navigation .nav-previous {
			float: left;
			width: 50%;
		}
		
		.post-navigation .nav-next {
			float: right;
			text-align: right;
			width: 50%;
		}
		
	}	

	@media (max-width: 767px) {
		
		.post-navigation .nav-previous  {
			margin-bottom: 30px;
		}
		
	}
	
	.post-navigation .nav-subtitle {
		display: block;
		font-size: 15px;
		text-transform: uppercase;
		color: $color-text-headings;
		margin-bottom: 7px;
	}
