// Change the position property of an element
/* =Position
   ========================================================================== */

	.ewf-is-static {
	  position: static;
	}

	.ewf-is-relative {
	  position: relative;
	}

	.ewf-is-fixed {
	  position: fixed;
	}

	.ewf-is-absolute {
	  position: absolute;
	}