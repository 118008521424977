
/* =Counter
   ========================================================================== */

    .ewf-counter {
		position: relative;
		margin-bottom: 30px;
		text-align: center;
	}

	.ewf-counter:after {
		visibility: hidden;
		display: block;
		height: 0;
		font-size: 0;
		content: " ";
		clear: both;		
	}
	
	.ewf-counter__icon {
		margin-bottom: 15px;
	}
	
	.ewf-counter__icon i {
		font-size: 60px;
		line-height: 1;
		color: $color-text-accent-2;
	}
	
	.ewf-counter__standard,
	.ewf-counter__symbol {
		font-size: 48px;
		line-height: 1;
		font-weight: 300;
		color: $color-text-headings;
	}
	
	.ewf-counter__title { 
		margin: 15px 0;
		font-size: 18px; 
		line-height: 30px;
		font-weight: 300;
		color: $color-text-headings;
		text-transform: uppercase;
	}

	.ewf-counter__title:last-child {
		margin-bottom: 0;
	}
