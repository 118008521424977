
/* =Button
   ========================================================================== */

    .ewf-btn {
		position: relative;
		background-clip: padding-box;
		display: inline-block;
		padding: 8px 30px;	
		border: 2px solid $color-text-link-hover;
		border-radius: 25px;
		margin-bottom: 30px;
		background-color: $color-text-link-hover;
		color: #ffffff;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 400;
		cursor: pointer;
		-webkit-appearance: none;
		transition: .3s all ease;
	} 
	
	.ewf-btn:focus,
	.ewf-btn:hover {
		border-color: $color-text-link-hover;
		background-color: transparent;
		color: $color-text-link-hover;	
	}

	.ewf-btn:active {
		border-color: $color-text-link-active;
		background-color: $color-text-link-active;
		color: #ffffff;
	}
	
	.ewf-btn i {
		font-size: 14px;
	}
	
	.ewf-btn i:last-child {
		margin-left: 15px;
	}