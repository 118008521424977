
/* =Main Slider
   ========================================================================== */

	.ewf-slider { 
		position: relative;	
		margin: 0 auto;	
		overflow: hidden;
	}

	.ewf-slider .ewf-slider__slides {
		margin-bottom: 0;
		list-style: none;
		position: relative;
	}

	.ewf-slider .ewf-slider__slides li {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		padding: 75px 30px;
		min-height: 250px;
	}	

	@media (min-width: 768px) {
		
		.ewf-slider .ewf-slider__slides li {
			padding: 75px 30px;
		}	
		
	}

	@media (min-width: 992px) {
		
		.ewf-slider .ewf-slider__slides li {
			padding: 75px 45px;
		}	
		
	}	
	
	@media (min-width: 1200px) {
		
		.ewf-slider .ewf-slider__slides li {
			padding: 100px 60px;
		}
		
	}	
	
	.ewf-slider .ewf-slider__slides li .ewf-slider-slide__overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: $color-background-slider-overlay;
		opacity: 0.85;
	}
	
	.ewf-slider .ewf-slider__slides li .ewf-slider-slide__overlay-left {
		position: absolute;
		z-index: 2;
		top: -10px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-background-general-section-overlay;
		opacity: 0.85;
		padding: 10px 0;
	}
	
	@media (min-width:991px) {
	
		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__overlay-left {
			left: 0px;	
			transform: skew(-13deg);
			padding: 10px 100px 10px 0;
			width: 50%;
		}	
	
	}	
	
	@media (min-width:1900px) {
	
		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__overlay-left {
			left: 120px;	
			transform: skew(-13deg);
			padding: 10px 0 10px 0;
			width: 40%;
		}	
	
	}	
	
	.ewf-slider .ewf-slider__slides li .ewf-slider-slide__content {
		position: relative;
		z-index: 2;
	}

	@media (min-width: 768px) {

		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__content {
			width: 730px;
			min-height: 350px;
			margin: 0 auto;
		}	

	}

	@media (min-width: 992px) {

		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__content {
			width: 940px;
			min-height: 400px;
		}	

	}
	
	@media (min-width: 1200px) {

		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__content {
			width: 1140px;
			min-height: 500px;
		}	

	}
	
	@media (min-width: 1600px) {

		.ewf-slider .ewf-slider__slides li .ewf-slider-slide__content {
			width: 1440px;
			min-height: 700px;
		}	

	}
	
	@media (min-width: 768px) {
		
		.ewf-slider-slide__content--align-left { }
		.ewf-slider-slide__content--align-center { text-align: center; }
		.ewf-slider-slide__content--align-right { text-align: right; }
	
	}
	
	.ewf-slider .ewf-slider-slide__content-wrap {}
	
	.ewf-slider .ewf-slider-slide__content-wrap *:last-child {
		margin-bottom: 0;
	}
	
	@media (min-width: 768px) {
	
		.ewf-slider-slide__content--valign-top  .ewf-slider-slide__content-wrap {}
		
		.ewf-slider-slide__content--valign-middle .ewf-slider-slide__content-wrap { 
			position: absolute; 
			top: 50%;
			width: 100%;
			transform: translateY(-50%);
		}
		
		.ewf-slider-slide__content--valign-bottom .ewf-slider-slide__content-wrap {
			position: absolute; 
			bottom: 0;
			width: 100%;
		}
	
	}
	
	.ewf-slider .ewf-slider__pager {
		position: absolute;
		width: 100%;
		bottom: 0;
		padding: 15px 30px;
		z-index: 3;
		box-sizing: border-box;
	}

	@media (min-width: 768px) {

		.ewf-slider .ewf-slider__pager {
			left: 50%;
			bottom: 30px;
			padding: 15px 0;
			width: 730px;
			transform: translateX(-50%);
		}

	}	

	@media (min-width: 992px) {

		.ewf-slider .ewf-slider__pager {
			width: 940px;
			bottom: 45px;
		}

	}

	@media (min-width: 1200px) {

		.ewf-slider .ewf-slider__pager {
			width: 1140px;
		}

	}	
	
	@media (min-width: 1600px) {

		.ewf-slider .ewf-slider__pager {
			width: 1440px;
		}

	}	
	
	@media (min-width: 1400px) {

		.ewf-slider .ewf-slider__pager {
			bottom: 60px;
		}

	}	
	
	.ewf-slider .ewf-slider__pager .slick-dots {
		list-style: none;
		margin-bottom: 0;
	}
	
	.ewf-slider .ewf-slider__pager .slick-dots:after {
		display: block;
		visibility: hidden;
		height: 0;
		font-size: 0;
		content: " ";
		clear: both;
	}
	
	.ewf-slider .ewf-slider__pager.ewf-slider__pager--align-left .slick-dots { text-align: left; }
	.ewf-slider .ewf-slider__pager.ewf-slider__pager--align-center .slick-dots { text-align: center; }
	.ewf-slider .ewf-slider__pager.ewf-slider__pager--align-right .slick-dots { text-align: right; }
	
	.ewf-slider .ewf-slider__pager .slick-dots li {
		position: relative;
		z-index: 2;
		display: inline-block;
		border-radius: 50%;
		padding: 5px;
		margin-right: 0;
	}	

	.ewf-slider .ewf-slider__pager .slick-dots li:last-child {
		margin-right: 0;
	}
	
	.ewf-slider .ewf-slider__pager .slick-dots li.slick-active {}

	.ewf-slider__pager button {
		display: block;
		padding: 0;
		width: 14px;
		height: 14px;
		border: 2px solid transparent;
		border-radius: 50%;
		cursor: pointer;
		outline: none;
		color: transparent;
		background-color: $color-text-accent-2;
		background-clip: padding-box;
		font-size: 0;
		line-height: 0;
	}

	.ewf-slider .ewf-slider__pager .slick-dots li.slick-active button {
		border: 2px solid $color-text-accent-1;
		background-color: transparent;
	}
	
	.ewf-slider .ewf-slider__arrows a {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: #ffffff;
		font-size: 18px; 
	}

	.ewf-slider .ewf-slider__arrows a:hover { 
		color: $color-text-accent-1; 
	}
	
	.ewf-slider .ewf-slider__arrows .slick-prev {
		left: 5px;
	}
	
	.ewf-slider .ewf-slider__arrows .slick-next {
		right: 5px;
	}
	
	
	@media (min-width: 767px) {
		
		.ewf-slider .ewf-slider__arrows a {
			font-size: 30px;
		}	
		
	}	

	@media (min-width: 992px) {
	
		.ewf-slider .ewf-slider__arrows .slick-prev {
			left: 15px;
		}
	
		.ewf-slider .ewf-slider__arrows .slick-next {
			right: 15px;
		}
	
	}

	@media (min-width: 1200px) {
	
		.ewf-slider .ewf-slider__arrows .slick-prev {
			left: 30px;
		}
		
		.ewf-slider .ewf-slider__arrows .slick-next {
			right: 30px;
		}
	
	}	
   