// Text helper classes for headings and body text
/* =Text
   ========================================================================== */

	.ewf-text-align--left {
	  text-align: left;
	}   
   
	.ewf-text-align--center {
	  text-align: center;
	}

	.ewf-text-align--right {
	  text-align: right;
	}

	.ewf-text-transform--uppercase { 
		text-transform: uppercase; 
	}
	