
/* ==========================================================================
   =Navigation 
   ========================================================================== */

	#header nav {
		text-align: right;
	}	
   
/* ==========================================================================
   =Menu 
   ========================================================================== */
   
/* =Menu Basics
   ========================================================================== */
   
	.sf-menu { 
		display: none;	
	} 
   
	@media  (min-width: 1200px) {
		.sf-menu { 
			display: inline-block; 
			margin-right: 50px;
		}
	}
   
	/*
	 * 1. Clearfix hack 
	 */
	 
	.sf-menu:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
   
	.sf-menu,
	.sf-menu ul {
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: left;
	}
	
	.sf-menu > li {
		display: inline-block;
	}

	.sf-menu > li > a {
		position: relative;
		display: block;
	}
	
	.sf-menu .sf-mega,
	.sf-menu li.dropdown ul {
		position: absolute;
		z-index: 1025; 	/* 1 */
		top: 100%;
		left: 0;
		display: none;
	}
	
	.sf-menu li.dropdown { position: relative; }
	
	.sf-menu li.dropdown ul ul {
		top: 0;
		left: 100%;
	}
	
	.sf-menu li:hover > .sf-mega,
	.sf-menu li.sfHover > .sf-mega,
	.sf-menu li.dropdown:hover > ul,
	.sf-menu li.dropdown.sfHover > ul { display: block; }

/* =Menu Skin
   ========================================================================== */
	
	.sf-menu a {
		display: block;
		color: $color-text-dropdown-menu-item;
		position: relative;
	}
	
	.sf-menu a:hover {
		color: $color-text-dropdown-menu-item-hover;
	}	
	
	.sf-menu li.dropdown a,
	.sf-menu li.mega a{ 
		padding: 7px 20px;
	}
	
	.sf-menu li.dropdown ul a,
	.sf-menu li.mega .sf-mega a{ 
		border-bottom: 1px solid $color-border-dropdown-menu-item;
	}
	
	.sf-menu li.dropdown > a {
		border-bottom: 0;
	}
	
	.sf-menu li.dropdown li > a:hover:before { 
		position: absolute;
		bottom: -1px;
		left: 0;
		width: 140px;
		border-bottom: 1px solid $color-border-dropdown-menu-item-hover;
		content: " ";
	}
	
	.sf-menu li:last-child > a { border-bottom: none; }
	
	.sf-menu > li > a,
	.sf-menu > li.dropdown > a,
	.sf-menu > li.mega > a {
		padding: 36px 0;
		color: $color-text-menu-item;
		line-height: 1;
		text-transform: uppercase;
		font-size: 16px;
		border-bottom: 1px solid #fff;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
	}
	
	.sf-menu > li > a,
	.sf-menu > li.dropdown > a,
	.sf-menu > li.mega > a {
		margin-right: 15px;
	}
	
	@media  (min-width: 1200px) {
	
		.sf-menu > li > a,
		.sf-menu > li.dropdown > a,
		.sf-menu > li.mega > a {
			margin-right: 30px;
		}
	
	}	
	
	@media  (min-width: 1600px) {
	
		.sf-menu > li > a,
		.sf-menu > li.dropdown > a,
		.sf-menu > li.mega > a {
			margin-right: 45px;
		}
	
	}	
	
	.sf-menu > li a i { 
	}
	
	.sf-menu > li.current > a {
		border-bottom-color: $color-text-menu-item-hover;
	} 
	
	.sf-menu > li.sfHover > a:focus,
	.sf-menu > li > a:focus,
	.sf-menu > li.sfHover > a:hover,
	.sf-menu > li > a:hover {
		color: $color-text-menu-item-hover; 
	}	

/* =DropDown
   ========================================================================== */
	
	/**
 	 * 1. allow long menu items to determine submenu width
 	 */
	
	.sf-menu li.dropdown ul {
		min-width: 240px; 	/* 1 */
		background-color: $color-background-menu-dropdown;
		left: -15px;	
	}
		
/* =Mega Menu Section
   ========================================================================== */
	
	.sf-mega {
		width: 100%;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;
		padding: 15px;
		margin-top: 0;	
		background-color: $color-background-menu-dropdown;
		color: $color-text-menu-item;
	}

	.sf-mega-section {
		float: left;
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;
		padding: 0 15px;
	}

	/**
 	 * 1. set mega menu section size, as a percentage of the mega menu width
 	 */
	
	.sf-mega.sf-mega-1-col .sf-mega-section{ width: 100%; } /* 1 */
	
	.sf-mega.sf-mega-2-col .sf-mega-section{ width: 50%; }
	
	.sf-mega.sf-mega-3-col .sf-mega-section{ width: 33.3333333333%; }
	
	.sf-mega.sf-mega-4-col .sf-mega-section{ width: 25%; }
	
	.sf-mega-section-title {}

/* =Menu Arrows
   ========================================================================== */
	
	/*
	.sf-menu .dropdown > a:after,
	.sf-menu .mega > a:after {
		content: "\e64b";
		font-family: 'themify';
		padding-left: 5px;
		font-size: 15px;
	}
	*/
	.sf-menu .dropdown .dropdown > a:after {
		content: "\e649";
		font-family: 'themify';
		padding-left: 5px;
		float: right;
		font-size: 15px;
	}
	
	
/* ==========================================================================
   =Mobile Menu 
   ========================================================================== */
			
	#mobile-menu { display: none; }
		
	/*
	 * 1. Clearfix hack 
	 */
	 
	#mobile-menu:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
	#mobile-menu {
		color: $color-text-dropdown-menu-item;
		border-bottom: 1px solid $color-border-dropdown-menu-item;
		margin-bottom: 0;
		background-color: $color-text-accent-1;
	}
	
	#mobile-menu li {	
		display: block;
		margin: 0;
	}
		
	#mobile-menu > li > ul, 
	#mobile-menu > li > ul > li > ul {
		display: none;
		margin-left: 0;
	}
	
	#mobile-menu .sf-mega {
		display: none;
		padding: 0;
		border: none;
		margin: 0;
	}
	
	#mobile-menu .sf-mega-section {
		float: none;
		width: 100%;
		padding: 0;
		border: none;
	}
	
	#mobile-menu .sf-mega-section ul { 
		margin-left: 0;
		margin-bottom: 0;
	}

	#mobile-menu li a {
		position: relative;
		display: block;
		padding: 13px 21px;
		border-top: 1px solid $color-border-dropdown-menu-item;
		font-size: 15px;
		text-align: left;
		text-decoration: none;
		color: $color-text-dropdown-menu-item;
	}
	
	#mobile-menu ul a { padding-left: 42px; }
	
	#mobile-menu ul li ul a  { padding-left: 52px; }

	#mobile-menu li.dropdown > a {padding-right: 84px; }
	
	#mobile-menu .mobile-menu-submenu-arrow {
		position: absolute;
		top: 0;
		right: 0;
		width: 52px;
		height: 100%;
		border-left: 1px solid $color-border-dropdown-menu-item;
		color: $color-text-dropdown-menu-item;
		font-size: 15px;
		line-height: 52px;
		text-align: center;
		cursor: pointer;
	}
	
	#mobile-menu .mobile-menu-submenu-arrow:hover { background-color: $color-background-menu-dropdown; }
	
	#mobile-menu li a:hover {}

/* ==========================================================================
   =Mobile menu trigger
   ========================================================================== */
				
	#mobile-menu-trigger { 
		display: inline-block;
		color: $color-text-mobile-menu-trigger;
		margin-left: 10px;
	}
	
	@media (min-width: 768px) {
	
		#mobile-menu-trigger { 
			margin-left: 15px;
		}
	
	}
	
	@media (min-width: 1200px) {

		#mobile-menu-trigger { 
			display: none;
		}	

	}
	
	#mobile-menu-trigger  i {
		font-size: 20px;
	}	
	