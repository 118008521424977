
/* =Map
   ========================================================================== */

	.ewf-map {
		display: block;
		width: 100%;
		height: 600px;
		margin: 0 0 60px 0;
		box-sizing: border-box;
		border-radius: 15px;
	}
	
	.ewf-map img { 
		max-width: none; 
	}
   