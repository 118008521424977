// Color helpers
/* =Colors
   ========================================================================== */

	.ewf-color-text {
		color: $color-text-general;
	}
	
	.ewf-color-heading {
		color: $color-text-headings;
	}
	
	.ewf-color-accent {
		color: $color-text-accent-1;
	}
	
	.ewf-color-accent-2 {
		color: $color-text-accent-2;
	}