
/* ==========================================================================
   =Back to top
   ========================================================================== */

	#back-to-top {
		position: fixed;
		z-index: 1010;
		right: -40px;
		bottom: 15px;
		width: 35px;
		height: 35px;
		border-radius: 50%;
		background-color: $color-text-accent-2;
		color: #ffffff;
		line-height: 35px;
		text-align: center;					
		cursor: pointer;
		-webkit-transition: all 0.4s ease 0s;
				transition: all 0.4s ease 0s;
	}
	
	#back-to-top i {
		-webkit-transition: all 0.4s ease 0s;
				transition: all 0.4s ease 0s;
	}
	
	#back-to-top:hover { background-color: $color-text-accent-1; }
	#back-to-top:hover i { color: #ffffff; }
	#back-to-top.visible { right: 13px; }
	#back-to-top.gone { right: -40px; }	
