
/* ==========================================================================
   =Comments, Pingbacks and Trackbacks
   ========================================================================== */
	
	/*
	 * 1. Clearfix hack 
	 */
	
	.comments-area:after,
	.comment-list:after	
	.comment:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
	.comments-area {
		margin-bottom: 60px;
	}
	
	.comment-list,
	.comment-list .children {
		list-style: none;
	}
	
	.comment-list {
		margin: 30px 0;
	}
	
	@media (max-width: 480px) {
		
		.comment-list .children {
			margin-left: 10px;
		}
		
	}

	.comment {}
	
	.no-comments, 
	.comment-awaiting-moderation {
		color: $color-text-headings;
	}
	
/* =Comment Meta
   ========================================================================== */	
   
	.comment-meta {
		margin: 15px 0;
	}
	
	.comment-author {
		position: relative;
		z-index: 2;
		text-transform: uppercase;
		font-weight: 400;
	}
	
	.comment-author .avatar {
		position: absolute;
		left: -120px;
		top: 0;
		height: 90px;
		width: 90px;
		border-radius: 15px;
	}
	
	@media (max-width: 480px) {
		
		.comment-author .avatar {
			position: relative;
			left: auto;
			top: auto;
			display: block;
			height: 48px;
			width: 48px;
			margin-bottom: 26px;
		}
		
	}
	
	.comment-author .says {
		display: none;
	}
	
	.comment-metadata > a {
		color: $color-text-general;
	}
	
	.comment-metadata a.comment-edit-link {}
	
/* =Comment Body
   ========================================================================== */	
   
	.comment  .comment-body {
		position: relative;
		padding: 15px 0 15px 120px;	
	}
	
	.comment-list > li:first-child > .comment-body {
		padding-top: 5px;
		border-top: 0;
	}
	
	@media (max-width: 480px) {
		
		.comment  .comment-body {
			padding-left: 0;
		}
		
	}
	
	.comment  .comment-body  *:last-child {
		margin-bottom: 0;
	}	
	
/* =Comment Reply
   ========================================================================== */	
   
	.reply {
		margin: 0 0;
		text-align: right;
	}
	
	.comment-reply-link {
		position: relative;
		background-clip: padding-box;
		display: inline-block;
		padding: 3px 30px;		
		border: 2px solid $color-border-forms-button;
		border-radius: 25px;
		background-color: $color-background-forms-button;
		color: $color-text-forms-button;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		-webkit-appearance: none; 
	}
	
	.comment-reply-link:hover,
	.comment-reply-link:focus {
		border: 2px solid $color-border-forms-button-hover;
		background-color: $color-background-forms-button-hover;
		color: $color-text-forms-button-hover;
	}
  
/* ==========================================================================
   =Comments Navigation
   ========================================================================== */

	.comment-navigation { 
		padding-top: 30px;
		margin-bottom: 30px; 
	}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.comment-navigation:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
	#comment-nav-above {
		padding-bottom: 30px;
		border-bottom: 1px solid $color-border-general;
	}
	
	#comment-nav-below {
		border-top: 1px solid $color-border-general;
	}

	.comment-navigation .nav-next a:after {
		font-family: 'themify';
		content: "\e649";
		padding-left: 5px;
		font-size: 13px;
	}

	.comment-navigation .nav-previous a:before {
		font-family: 'themify';
		content: "\e64a";
		padding-right: 5px;
		font-size: 13px;
	}		
	
	.comment-navigation .nav-next{
		float: right;
		text-align: right;
		width: 50%;
	}
	
	.comment-navigation .nav-previous {
		float: left;
		width: 50%;
	}