 
/* =Section
   ========================================================================== */

    .ewf-section {
		position: relative;
		z-index: 1;
		padding: $spacing-section-small 0;
		margin-bottom: 0;
		background: no-repeat center center;
		background-size: cover;
	}

	.ewf-section--parallax { 
		background-attachment: fixed;
		background-position: top center;
	}

	.ewf-section__overlay-color {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $color-background-general-section-overlay;
		opacity: 0.85;
	}
	
	.ewf-section__overlay-color-left,
	.ewf-section__overlay-color-right {
		position: absolute;
		z-index: 2;
		top: -10px;
		width: 100%;
		height: 100%;
		background-color: $color-background-general-section-overlay;
		opacity: 0.85;
		padding: 10px 0;
	}
	
	@media (min-width:991px) {
	
		.ewf-section__overlay-color-left {
			left: -90px;	
			transform: skew(-13deg);
			padding-right: 150px;
			width: 50%;
		}
		
		.ewf-section__overlay-color-right {
			right: -90px;
			transform: skew(-13deg);
			padding-left: 150px;
			width: 50%;
		}	
		
	}	
	
	.ewf-section__overlay-pattern {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	
	.ewf-section__video-background-yt,
	.ewf-section__video-background-local {
		position: absolute;
		z-index: 2;
		overflow: hidden;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
	
	.ewf-section__video-background-local video {
		width: 100%;
		height: auto;
	}
	
	.ewf-section__content {
		position: relative;
		z-index: 3;
	}
   
	/* Section full height Modifiers */
	
	.ewf-fullscreen  { 
		box-sizing: border-box;	
		height: 100vh;
	}
	
	/* Section top/bottom spacing Modifiers */
	
	.ewf-section--spacing-none 		{ padding: 0 0; }
	
	@media (min-width: 768px) {
		
		.ewf-section--spacing-small 	{ padding: $spacing-section-small 0; }
		.ewf-section--spacing-medium 	{ padding: $spacing-section-medium 0; }
		.ewf-section--spacing-large 	{ padding: $spacing-section-large 0; }
	   
	}
	
	@media (min-width: 992px) {
		
		.ewf-section {
			padding: $spacing-section-medium 0;
		}
		 
	}	 
	
	@media (min-width: 1200px) {
		
		.ewf-section {
			padding: $spacing-section-large 0;
		 }
	
	}	 