   
/* =Iconbox
   ========================================================================== */

	.ewf-iconbox {
		position: relative;
		margin-bottom: 30px;
	}
	
	.ewf-iconbox:after {
		visibility: hidden;
		display: block;
		height: 0;
		font-size: 0;
		content: " ";
		clear: both;		
	}
	
	.ewf-iconbox *:last-child {
		margin-bottom: 0;
	}
	
	.ewf-iconbox__icon {
		margin-bottom: 30px;
	}
	
	.ewf-iconbox__icon i {
		display: inline-block;
		font-size: 60px;
		line-height: 1;
		color: $color-text-accent-2;
	}

	.ewf-iconbox__content {}	
	
	.ewf-iconbox__title {
		margin-bottom: 30px;
		color: $color-text-headings;
		text-transform: uppercase;
	}
	
	.ewf-iconbox__description {
		margin-bottom: 30px;
	}
	
	
	/* Blurb Modifier - icon on left */
	
	.ewf-iconbox--icon-left-aligned {
		text-align: left;
	}
	
	.ewf-iconbox--icon-left-aligned .ewf-iconbox__icon {
		float: left;
		margin-bottom: 0;
	}
	
	.ewf-iconbox--icon-left-aligned .ewf-iconbox__content {
		margin-left: 90px;
	}
	
	
	@media (min-width: 992px) {
	
		.ewf-iconbox { margin-bottom: 60px; }
	
	}	