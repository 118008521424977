
/* =Location
   ========================================================================== */

   .ewf-location {
	   position: relative;
	   background-color: $color-background-general;
	   padding: 30px;
	   border-radius: 25px;
	   margin-bottom: 30px;
   }
   
   .ewf-location .ewf-contact-info,
   .ewf-location .ewf-contact-info li { margin-bottom: 0; }