
/* ==========================================================================
   =Widgets: Default WordPress Widgets
   ========================================================================== */
	
	.widget { 
		margin-bottom: 30px; 
	}

	#footer [class*=col]:last-child .widget:last-child,
	#footer-bottom [class*=col]:last-child .widget:last-child {
		margin-bottom: 0; 	
	}
	
	@media ( min-width: 768px) {
		
		.widget { 
			margin-bottom: 45px; 
		}	
		
		.widget:last-child { 
			margin-bottom: 0 !important; 
		}
		
	}
	
	/**
	 * 1. The last element of every widget shouldn't have a margin bottom. used for spacing purposes
	 */
	
	.widget > *:last-child { 
		margin-bottom: 0; /* 1 */
	}
	
	.widget-title { 
		margin-bottom: 30px;
	}	
	
	@media ( min-width: 768px) {
	
		.widget-title { 
			margin-bottom: 45px;
		}	
	
	}
	
/* =Archive Widget
   ========================================================================== */
	
	.widget_archive {}
	
	.widget_archive ul { list-style: none; }
	
	.widget_archive li { 
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	} 
	
	.widget_archive li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_archive li a {}
	
	.widget_archive li span { float: right; }
	
	.widget_archive select { margin-bottom: 0; }
	
	.widget_archive option {}	
	
/* =Audio Widget
   ========================================================================== */	
	
	.widget_media_audio {}
		
/* =Calendar Widget
   ========================================================================== */
	
	.widget_calendar {}
	
	#calendar_wrap {}
		
	#calendar_wrap table {
		position: relative;
		border-collapse: separate;
		border-spacing: 5px;
	}		
		
	#calendar_wrap table,
	#calendar_wrap th,
	#calendar_wrap td {
		border: 0;
		padding: 0;
		text-align: center;
		
	}
	
	#wp-calendar caption {
		margin-bottom: 0;
		font-weight: 400;
		text-transform: uppercase;
		text-align: center;
		background-color: #ffffff;
		border: 1px solid $color-border-general;
		border-radius: 15px;
		color: $color-text-link;
		line-height: 60px;
	}
	
	#wp-calendar th {
		color: $color-text-link;
		line-height: 60px;
	}	
	
	#wp-calendar tbody td:not(.pad) {
		border: 1px solid $color-border-general;
		line-height: 45px;
		border-radius: 10px;
	}
	
	#wp-calendar  tbody td a {
		display: block;
		margin: 0;
		background-color: #ffffff;
		color: $color-text-link;
		line-height: 45px;
		text-align: center;
		border-radius: 10px;
	}
	
	#wp-calendar  tbody td a:hover {
		background-color: $color-text-link-hover;
		color: #ffffff;
	}
	
	#wp-calendar #today {
		background-color: $color-text-link;
		color: #ffffff;
	}
	
	#wp-calendar tfoot {
		height: 0;
		float: left;
	}
	
	#wp-calendar #prev a {
		position: absolute;
		top: 0;
		left: 5px;
		display: inline-block;
		width: 30px;
		line-height: 60px;
		font-size: 0;
	}
	
	#wp-calendar #prev a:before {
		position: relative;
		display: inline-block;
		color: $color-text-link;
		content: "\e64a";
		font-family: 'themify';
		font-size: 18px;
		line-height: 60px;
		text-align: center;
	}
	
	#wp-calendar #prev a:hover:before {
		color: $color-text-link-hover;
	}
	
	#wp-calendar #next a {
		position: absolute;
		top: 0;
		right: 5px;
		display: inline-block;
		width: 30px;
		line-height: 60px;
		font-size: 0;
	}
	
	#wp-calendar #next a:before {
		position: relative;
		display: inline-block;
		color: $color-text-link;
		content: "\e649";
		font-family: 'themify';
		font-size: 18px;
		line-height: 60px;
		text-align: center;
	}
	
	#wp-calendar #next a:hover:before {
		color: $color-text-link-hover;
	}
	
	@media ( min-width: 992px) {
	
		#wp-calendar tbody td:not(.pad) {
			line-height: 60px;
		}
	
		#wp-calendar  tbody td a {
			line-height: 60px;
		}
	
	}
	
/* =Categories Widget
   ========================================================================== */
	
	.widget_categories {}
	
	.widget_categories ul { list-style: none; }
	
	.widget_categories ul ul { margin-bottom: 0; }
	
	.widget_categories li { 
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	} 

	.widget_categories ul ul li {
		border-bottom: 0;
	}
	
	.widget_categories li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_categories ul ul li:first-child {
		border-top: 0;
	}
	
	.widget_categories li a {}
	
	.widget_categories li span { float: right; }
	
	.widget_categories select { margin-bottom: 0; }
	
	.widget_categories option {}	

/* =Custom Html Widget
   ========================================================================== */
	
	.widget_custom_html {}

	.textwidget.custom-html-widget {}		
	
/* =Gallery Widget
   ========================================================================== */
   
	.widget_media_gallery {}
	
	/*
	 * 1. Clearfix hack 
	 */
	 
	.widget_media_gallery:after {	/* 1 */
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
	
/* =Image Widget
   ========================================================================== */

	.widget_media_image {}
      
/* =Meta Widget
   ========================================================================== */
	
	.widget_meta {}
	
	.widget_meta ul { list-style: none; }
	
	.widget_meta li { 
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	} 
	
	.widget_meta li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_meta li:last-child { margin-bottom: 0; }

	.widget_meta li a {}
	
/* =Navigation Menu Widget
   ========================================================================== */
	
	.widget_nav_menu {}
	
	.widget_nav_menu ul { list-style: none; }
	
	.widget_nav_menu li { 
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	} 
	
	.widget_nav_menu ul ul li {
		border-bottom: 0;
	}
	
	.widget_nav_menu li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_nav_menu ul ul li:first-child {
		border-top: 0;
	}

	.widget_nav_menu li a {
		display: inline-block;
	}
		
/* =Pages Widget
   ========================================================================== */
	
	.widget_pages {}
	
	.widget_pages ul { list-style: none; }
	
	.widget_pages li { 
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	} 
	
	.widget_pages ul ul li {
		border-bottom: 0;
	}
	
	.widget_pages li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_pages ul ul li:first-child {
		border-top: 0;
	}
	
	.widget_pages li a {}

/* =Recent Comments Widget
   ========================================================================== */
	
	.widget_recent_comments {}
	
	.widget_recent_comments ul { list-style: none; }
	
	.widget_recent_comments li { margin-bottom: 10px; } 
	
	.widget_recent_comments li:last-child { margin-bottom: 0; }
	
	.widget_recent_comments li a {}

/* =Recent Posts Widget
   ========================================================================== */
	
	.widget_recent_entries {}
	
	.widget_recent_entries ul { list-style: none; }
	
	.widget_recent_entries ul li { 
		position: relative;
		border-bottom: 1px solid $color-border-general;
		padding: 9px 0;
	}
	
	.widget_recent_entries ul li:first-child {
		border-top: 1px solid $color-border-general;
	}
	
	.widget_recent_entries ul li a {
		display: block;
	}
	
	.widget_recent_entries ul li .post-date { display: block; }

/* =RSS Widget
   ========================================================================== */
	
	.widget_rss {}

	.widget_rss ul {
		margin: 0;
		list-style: none;
	}

	.widget_rss li { margin-bottom: 30px; }

	.widget_rss ul:last-child,
	.widget_rss li:last-child { margin-bottom: 0; }

	.widget_rss ul li a.rsswidget {
		margin-top: 5px; 
		margin-bottom: 30px; 
	}

	.widget_rss .rsswidget img { margin-top: -5px; }

	.widget_rss .rss-date,
	.widget_rss cite 	{
		display: block;
		padding: 15px 0;
	}
	
	.widget_rss cite {
		color: $color-text-headings;
	}

/* =Search Widget
   ========================================================================== */
	
	.widget_search {}
	
	.search-form {
		position: relative;
	}

	.search-field {}
	
	.widget_search .search-submit {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 25px 25px 0;
		padding: 13px 30px;
		background-color: $color-text-link;
		border-color: $color-text-link;
		text-align: center;
	}
	
	.widget_search .search-submit:hover, 
	.widget_search .search-submit:focus {
		background-color: $color-text-link-hover;
		border-color: $color-text-link-hover;
		color: #ffffff;
	}
	
	.widget_search .search-submit:active {
		background-color: $color-text-link-active;
		border-color: $color-text-link-active;
		color: #ffffff;
	}

/* =Tag Cloud Widget
   ========================================================================== */
	
	.widget_tag_cloud {}
	
	.widget_tag_cloud a {
		display: inline-block;
		margin: 0 5px 10px 0;
		padding: 4px 30px;
		border-radius: 25px;
		border: 1px solid $color-border-general;
		color: $color-text-general;
		transition: background-color 250ms cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
	}
	
	.widget_tag_cloud a:hover{
		background-color: $color-text-link-hover;
		color: #ffffff;
		border-color: $color-text-link-hover;
	}

/* =Text Widget
   ========================================================================== */
	
	.widget_text {}
	
	.textwidget {}
	
	/**
	 * 1. The last element inside text widget shouldn't have margin bottom
	 */
	
	.textwidget > *:last-child {
		margin-bottom: 0; /* 1 */
	}

/* =Video Widget
   ========================================================================== */   
	
	.widget_media_video {}
	