
/* =Latest Posts
   ========================================================================== */

	.ewf-post-summary {
	   margin-bottom: 30px;
	}
   
	.ewf-post-summary .post-thumbnail {
		margin-bottom: 0;
	}
	
	.ewf-post-summary .post-thumbnail img {
		border-radius: 15px;
		margin-bottom: 15px;
	}
	
	.ewf-post-summary .post-meta {
		padding: 0;
	}
	
	.ewf-post-summary .post-title {
		padding: 0;
		margin: 10px 0;
	}
	
	@media (min-width: 768px) {
		
		.ewf-post-summary .post-thumbnail img {
			margin-bottom: 0;
		}
		
	}